import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import app from '../App/ducks';
import settings from '../Settings/ducks';
import heartbeat from '../Heartbeat/ducks';
import ghostwriter from '../Ghostwriter/ducks';
import tasks from '../Tasks/ducks';
import assets from '../Assets/ducks';
import smoc from '../SMOC/ducks';
import { jwtMiddleware, jwtListener, jwtReducer } from './jwtMiddleware';
import { FOUNDATION } from 'config/features';

import { initialize } from '../App/actions/init';

const initializeStore = () => {
  const rootReducer = combineReducers({
    settings,
    app,
    ghostwriter,
    heartbeat,
    tasks,
    assets,
    smoc,
    token: jwtReducer,
  });

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(jwtMiddleware, thunk)));
  store.subscribe(() => jwtListener(store.getState()));
  if (!FOUNDATION) {
    store.dispatch(initialize());
  }

  return store;
};

export default initializeStore;
