const errorCodes = {
  HTTP_RESPONSE_INVALID_CONTENT_TYPE: 'HTTP_RESPONSE_INVALID_CONTENT_TYPE',
  HTTP_RESPONSE_STATUS_NOT_2XX: 'HTTP_RESPONSE_STATUS_NOT_2XX',
  HTTP_RESPONSE_STATUS_UNAUTHORIZED: 'HTTP_RESPONSE_STATUS_UNAUTHORIZED',
};

const getErrorCodeForHTTPStatusCode = statusCode => {
  switch (statusCode) {
    case 401:
      return errorCodes.HTTP_RESPONSE_STATUS_UNAUTHORIZED;
    default:
      return errorCodes.HTTP_RESPONSE_STATUS_NOT_2XX;
  }
};

const tryToParseAsJsonResponse = async response => {
  // Header may have character set appended at the end.
  const contentTypeHeader = response.headers.get('Content-Type');
  if (!contentTypeHeader || contentTypeHeader.indexOf('application/json') !== 0) {
    throw new Error(errorCodes.HTTP_RESPONSE_INVALID_CONTENT_TYPE);
  }

  return response.json();
};

const checkStatusCode = async (response, returnErrorMessage) => {
  if (response.ok) {
    return;
  }

  let error = new Error(getErrorCodeForHTTPStatusCode(response.status));
  if (returnErrorMessage) {
    try {
      const serverError = await tryToParseAsJsonResponse(response);
      if (serverError.message) {
        error = new Error(serverError.message);
      }
    } catch (e) {}
  }

  throw error;
};

const fetchJSON = async (url, options) => {
  const {
    method = 'GET',
    body,
    stringifyBody = true,
    token,
    parseResult = true,
    returnErrorMessage = true,
    contentType,
    headers,
    signal,
  } = options;

  const mappedOptions = {
    headers: {
      'Content-Type': contentType || 'application/json',
      Accept: 'application/json',
      credentials: 'include',
      ...(token && { Authorization: `Bearer ${token}` }),
      ...headers,
    },
    method,
    ...(body && { body: stringifyBody ? JSON.stringify(body) : body }),
    signal,
  };

  const response = await fetch(url, mappedOptions);

  await checkStatusCode(response, returnErrorMessage);
  return parseResult ? tryToParseAsJsonResponse(response) : response;
};

export const get = fetchJSON;

export const post = (url, options) => fetchJSON(url, { method: 'POST', ...options });

export const put = (url, options) => fetchJSON(url, { method: 'PUT', ...options });

export const del = (url, options) => fetchJSON(url, { method: 'DELETE', parseResult: false, ...options });
