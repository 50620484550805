import { ZITADEL_AUTHORITY, ZITADEL_CLIENT_ID, ZITADEL_POST_LOGOUT, ZITADEL_SCOPE } from './env';

const zitadelConfig = {
  authority: ZITADEL_AUTHORITY || 'http://zitadel',
  post_logout_redirect_uri: ZITADEL_POST_LOGOUT || 'http://localhost:3000',
  client_id: ZITADEL_CLIENT_ID || '261161323159683705@connect-foundation',
  redirect_uri: `${window.location.origin}/callback`,
  scope: ZITADEL_SCOPE || 'openid profile email offline_access',
};

export default zitadelConfig;
