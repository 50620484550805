import {
  CONFIGURATION_API_URL,
  RESOURCES_API_URL,
  LOGIN_API_URL,
  INSIGHTS_API_URL,
  BACKEND_HOST,
  SERVER_HOST,
  DATALAKE_API_HOST,
  DATALAKE_GRAPHQL_HOST,
} from './env';

export const backendHost = BACKEND_HOST || '';
export const serverHost = SERVER_HOST || `${backendHost}/metercloud-frontend-server`;

export const ghostwriter = `${serverHost}/ghostwriter-api/ghostwriter`;
export const datalakeGraphql = DATALAKE_GRAPHQL_HOST;
export const datalakeApi = DATALAKE_API_HOST;
export const openApiSpecLegacy = `${backendHost}/fs/resources/flows`;
export const openApiSpec = `${backendHost}`;
export const configuration = CONFIGURATION_API_URL || `${backendHost}/utilihive-frontend-configuration-server`;
export const resources =
  (RESOURCES_API_URL && `${RESOURCES_API_URL}/api`) || `${backendHost}/utilihive-integration-resource-registry/api`;
export const login = (LOGIN_API_URL && `${LOGIN_API_URL}/api`) || `${backendHost}/metercloud-integration-identity/api`;
export const insights =
  (INSIGHTS_API_URL && `${INSIGHTS_API_URL}/api/v1`) || `${backendHost}/metercloud-integration-insights/api/v1`;
export const insightsV2 =
  (INSIGHTS_API_URL && `${INSIGHTS_API_URL}/api/v2`) || `${backendHost}/metercloud-integration-insights/api/v2`;
export const adr = `${serverHost}/api/adr`;
export const taskManager = `${backendHost}/utilihive-task-manager`;
export const eve = `${serverHost}/eve-api`;
export const m3 = `${serverHost}/m3-api`;
