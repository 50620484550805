import { useMemo } from 'react';
import { defineMessage } from 'react-intl';
import SecurityIcon from '@mui/icons-material/Security';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GroupsIcon from '@mui/icons-material/Groups';
import ComputerIcon from '@mui/icons-material/Computer';
import TuneIcon from '@mui/icons-material/Tune';

import MODULE_NAMES from 'App/config/moduleNames';

export const MODULE_PATH = 'security';

export const AUTHENTICATION = 'authentication/*';
export const AUTHORIZATION = 'authorization/*';
export const SESSION_MANAGEMENT = 'session-management/*';
export const KNOWN_CLIENTS = 'known-clients';
export const LOGIN_UI_CUSTOMIZATION = 'login-ui-customization';

const children = [
  {
    path: AUTHENTICATION,
    lazy: () => import(/* webpackChunkName: "Security:Authentication" */ '../scenes/Authentication'),
    intlMessage: defineMessage({ id: 'route.security.authentication', defaultMessage: 'Authentication' }),
    Icon: AdminPanelSettingsIcon,
  },
  {
    path: AUTHORIZATION,
    lazy: () => import(/* webpackChunkName: "Security:Authorization" */ '../scenes/Authorization'),
    intlMessage: defineMessage({ id: 'route.security.authorization', defaultMessage: 'Authorization' }),
    Icon: VerifiedUserIcon,
  },
  {
    path: SESSION_MANAGEMENT,
    lazy: () => import(/* webpackChunkName: "Security:SessionManagement" */ '../scenes/SessionManagement'),
    intlMessage: defineMessage({ id: 'route.security.sessionManagement', defaultMessage: 'Session Management' }),
    Icon: GroupsIcon,
  },
  {
    path: KNOWN_CLIENTS,
    lazy: () => import(/* webpackChunkName: "Security:KnownClients" */ '../scenes/KnownClients'),
    intlMessage: defineMessage({ id: 'route.security.knownClients', defaultMessage: 'Known Clients' }),
    Icon: ComputerIcon,
  },
  {
    path: LOGIN_UI_CUSTOMIZATION,
    lazy: () => import(/* webpackChunkName: "Security:LoginUICustomization" */ '../scenes/LoginUICustomization'),
    intlMessage: defineMessage({ id: 'route.security.knownClients', defaultMessage: 'Login UI Customization' }),
    Icon: TuneIcon,
  },
];

const security = {
  path: MODULE_PATH,
  lazy: () => import(/* webpackChunkName: "Security" */ 'Security'),
  intlMessage: defineMessage({ id: 'route.security', defaultMessage: 'Security' }),
  Icon: <SecurityIcon />,
  moduleNameForOrganisationAccess: MODULE_NAMES.SECURITY,
  children,
};

export const useRoutes = () =>
  useMemo(
    () => ({
      intlMessage: security.intlMessage,
      root: security.path,
      Icon: security.Icon,
      moduleNameForOrganisationAccess: security.moduleNameForOrganisationAccess,
      routes: security.children.map(({ path, intlMessage, Icon, restrictRoleAccess, index }) => ({
        path,
        intlMessage,
        Icon,
        restrictRoleAccess,
        index,
      })),
    }),
    []
  );

export default security;
