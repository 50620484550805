import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import GreenbirdLogo from '../../presentation/components/Logo/Greenbird_bird_icon.svg?react';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const LoadingIcon = () => {
  const geThemeFeature = useFeatureToggle(FEATURE_TOGGLES.GE_THEME);
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
    >
      {geThemeFeature ? (
        <Typography variant="h1">
          <FormattedMessage id="general.gridos" defaultMessage="GridOS" />
        </Typography>
      ) : (
        <GreenbirdLogo style={{ width: 200 }} />
      )}
      <LinearProgress color="primary" sx={{ width: 400, mt: 5 }} />
    </Box>
  );
};

export default LoadingIcon;
